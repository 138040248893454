<div class="wrapper">

  <div class="btn-wrap">
    <ion-buttons>
      <ion-button (click)="dismiss()" size="small">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>

  <h5>Choose Campaign</h5>

  <ng-container *ngIf="campaigns && campaigns.length > 0">

    <ion-content>

      <ion-row>
        <ion-col class="ion-no-padding" size="12" *ngFor="let campaign of campaigns">
          <div 
            class="campaign-box p-box-popover"
            *ngIf="campaign.cloning_status === 'ready'" 
            (click)="openCampaing(campaign)">
            <div 
              class="color-box"
              style="--background: {{ this.formProcessor.getProjectUniqBadgeColor(campaign?.id) }};">
              {{ campaign?.title[0] }}
            </div>
            <div class="name-box">
              {{ campaign?.title }}
              <ion-icon 
                class="checkmark-circle" 
                name="checkmark-circle" 
                color="dark"
                *ngIf="campaign.id === dataSource.currentActiveProject.id">
              </ion-icon>
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
          </div>
        </ion-col>
      </ion-row>

    </ion-content>

  </ng-container>

</div>