import { Injectable } from '@angular/core';

import { AlertController, ModalController, LoadingController, ToastController, PopoverController, AnimationController } from '@ionic/angular';

import { VideoModalComponent } from '@components-v2/video-modal/video-modal.component';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


import { DataSourceService } from '@services/data-source.service';

import { Router, ActivatedRoute  } from '@angular/router';

import { AlertPage } from './../../app-v2/templates/alert/alert.page';
import { DetailVariationPage } from './../../app-v2/templates/detail-variation/detail-variation.page';
import { LoadingBoxComponent } from '@components/loading-box/loading-box.component';
import { SwUpdate } from '@angular/service-worker';

import { NavigationEnd } from '@angular/router';

import { environment } from '@env/environment';
import { Title } from "@angular/platform-browser";


import { PopupShareLinkComponent } from '@components-v2/popup-share-link/popup-share-link.component';

import { ProfileUpgradeModalComponent } from '@app/-core/components-v2/profile-upgrade-modal/profile-upgrade-modal.component';
import { FunnelsCreateModalComponent } from '@app/-core/components-v2/funnels-create-modal/funnels-create-modal.component';

import { AiLoadingScreenComponent } from '@app/-core/components-v2/ai-loading-screen/ai-loading-screen.component';

import { UniversalAccountUpgradeModalComponent } from '@app/-core/components-v2/universal-account-upgrade-modal/modal.component';

import { AuthService } from '@services/auth.service';

import { AssetInPackCreateModalComponent } from '@app/-core/components-v2/asset-in-pack-create-modal/asset-in-pack-create-modal.component';

@Injectable({
  providedIn: 'root'
})
export class FormProcessorService {

  // Env Representation
  env = environment;
  public enviroment(key: any) {
    try {
      return environment[key];
    } catch (err) {
      return null;
    }
  }

  updateAlert;

  fontLoadedCache: any = [];

  universalPlatformLogoImageCacheTimestamp: any = null;

  campaignCloningToaster;

  public isMobileView = false;

  // renderToolbar = false;

  constructor(
    public alertController: AlertController,
    public modalController: ModalController,
    public popoverController: PopoverController,
    private _sanitizer: DomSanitizer,

    public dataSource: DataSourceService,

    private router: Router,
    private route: ActivatedRoute,

    public loadingController: LoadingController,
    public toastController: ToastController,
    private serviceWorkerUpdate: SwUpdate,

    private titleService: Title,
    public auth: AuthService,
    // private ai: AiService,

  ) { 

    // this.presentWebisteSettingsUpdateToster();

    /*
    let callMe = (async () => {
      const modal = await this.modalController.create({
        component: LoadingBoxComponent,
        cssClass: 'application-v2 modal-v2 loading-box-modal',
        componentProps: {
          aiAnimation: true
        }
      });
      await modal.present();
    });
    callMe();
    */

    this.universalPlatformLogoImageCacheTimestamp = Math.random().toString(36).slice(2, 7);

    // this.openShareLinkPopup('hihihi', '123', 'XZC');
    
    /*

    * Unnsessesary for now maybe for ever ? /*/
  }

  showNotification(message, color = 'success', duration = 2000) {
    this.toastController.create({
      message,
      duration,
      color,
      position: 'bottom',
      cssClass: 'application-v2 toast-v2'
    }).then(toast => toast.present());
  }

  async showMessage(message) {
    const alert = await this.alertController.create({
      cssClass: 'application-v2 alert-v2',
      message,
      buttons: ['Ok']
    });
    return await alert.present();
  }

  async showComplexMessage(options) {
    const alert = await this.alertController.create({
      ...options,
      cssClass: 'application-v2 alert-v2 ' + (options.cssClass ? options.cssClass : ''),
    });
    return await alert.present();
  }

  getProjectUniqBadgeColor(id) {
    const colors = [
      '#4A81EB', '#47A9C8', '#62B99B', '#529E4C',
      '#8E9E4C', '#D49C48', '#D06637', '#947443',
      '#CF405B', '#AD38A1', '#7866E1'];

    const uniq = id % 11;

    return colors[uniq];

  }

  async openVideoPlayer(url) {
    const modal = await this.modalController.create({
      component: VideoModalComponent,
      cssClass: 'application-v2 modal-v2 video-player-modal',
      componentProps: {
        frame: this.getPlayerIframe(url),
      }
    });
    return await modal.present();
  }

  getPlayerIframe(video) {
    const source = video.split('/');
    console.log('getPlayerIframe', source);
    let frame = '';

    if ( source[2] === 'www.youtube.com' ) {
      console.log('source', source[3].split('?v='));
      const id = source[3].split('?v=')[1];
      frame = '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + id + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    }

    if ( source[2] === 'geek-freemarketing.wistia.com' ) {
      frame = '<iframe src="//fast.wistia.net/embed/iframe/' + source[4] + '" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe>';
    }

    return this._sanitizer.bypassSecurityTrustHtml(frame);

  }

  // Mediate Asset Actions

  async mediateCreateScript(params = {}, type = 'script') {

    console.log('mediateCreateScript()');

    // const integrity = (this.item.plan[0].progtess + this.item.plan[1].progtess) / 2;
    // console.log('Item plan integrity', this.item.plan, integrity);
    // themes_list: 'roadmap'

    let integrity = 0;
    const projectId = this.dataSource.currentActiveProject.id;
    this.dataSource.viewCampaing(projectId, { tags: 999 }).subscribe( async (res) => {

      console.log('viewCmp', res);
      integrity = (res.plan[0].progtess + res.plan[1].progtess) / 2;

      if ( integrity < 100 ) {
        const modal = await this.modalController.create({
          component: AlertPage,
          cssClass: 'application-v2 modal-v2 quick-heads-up-modal',
          componentProps: {

          }
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        // console.log('Wait for data:', data);
        if (data && data.forward ) {

          if ( type === 'design') {
            this.mediateSelectVariation({themes_list: 'lmb_design_studio'}, projectId);
          } else {
            this.router.navigate(['/app/assets/create/'], { queryParams: { ...params } });
          }

        }
      } else {

        if ( type === 'design') {
          this.mediateSelectVariation({themes_list: 'lmb_design_studio'}, projectId);
        } else {
          this.router.navigate(['/app/assets/create/'], { queryParams: { ...params } });
        }

      }

    });


  }

  async mediateEditScript(script) {
    // -
    console.log(script);
    const options = {
      // cssClass: 'my-custom-class',
      header: 'Edit Script',
      inputs: [
        {
          name: 'title',
          // type: 'text',
          placeholder: 'Script Title',
          value: script.title,
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          // role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (res) => {
            console.log('Confirm Ok');
            console.log(res);
            this.dataSource.editScript(this.dataSource.currentActiveProject.id, script.id, res).subscribe( (subRes) => {
              console.log(subRes);
              // this.viewCurrentCampaing();
            });
          }
        }
      ]
    }

    this.showComplexMessage(options);

  }

  async mdeiateOpenPlan(id, template, item) {
    if( id === -1 ) {
      // its exception
      console.log(item);
      if ( item.hook_id ) {
        // themes_list
        this.router.navigate(['/app/editor/' + item.hook_id]);
      } else {
        this.mediateCreateScript({themes_list: 'roadmap'});
      }
      return;
    }
    // -
    /* item.plan
    Hey there, we noticed you have not completed your planning phase yet. Be aware that we use the information in the planning phase to create your assets. Creating assets before your plan is completed will leave you with more information to fill in or require you to delete and recreate the asset once your plan is done.
    */
    template += 1;
    this.router.navigate(['/app/plan/' + id], { queryParams: {template} });
  }

  async mediateOpenScript(script) {
    // -

    const loading = await this.loadingController.create({
      // cssClass: 'my-custom-class',
      message: 'Compiling Asset, Please Wait...',
      // duration: 2000,
    });
    await loading.present();

    this.dataSource.viewScript(script.id, false, {stages: 0, wowpreview: 0, show_pack: 0, presets: 0}).subscribe( async (res) => {
      console.log('Analyze:', res, script.id);
      if (res.template) {
        // Template binded

        console.log('Restore', res);

        if (res.edit_type === 0) {
          this.router.navigate(['/app/script/' + script.id]);
          setTimeout( async () => {
            await loading.dismiss();
          });
        } else {
          this.router.navigate(['/app/editor/' + script.id]);
          setTimeout( async () => {
            await loading.dismiss();
          }, 2000);
        }

      } else {
        // No template binded
        this.router.navigate(['/app/templates/' + script.id]);
      }
    });
  }

  async mediateDeleteScript(id) {
    this.dataSource.deleteScript(this.dataSource.currentActiveProject.id, id).subscribe( (res) => {
      // this.viewCurrentCampaing();
      var evt = new CustomEvent("AssetEvent", {detail: {action: 'deleted', detail: "Asset was deleted", payload: {...res, originalId: id} }});
      (window as any).dispatchEvent(evt);
    });
  }

  async mediateCloneScript(id) {
    // -
    this.dataSource.cloneScript(this.dataSource.currentActiveProject.id, id).subscribe( (res) => {
      // this.viewCurrentCampaing();
      var evt = new CustomEvent("AssetEvent", {detail: {action: 'cloned', detail: "Asset was cloned", payload: {...res, originalId: id} }});
      (window as any).dispatchEvent(evt);
    });
  }

  async mediateCloneScriptPack(id) {
    // -
    this.dataSource.cloneScriptPack(this.dataSource.currentActiveProject.id, id).subscribe( (res) => {
      // this.viewCurrentCampaing();
      var evt = new CustomEvent("AssetEvent", {detail: {action: 'cloned', detail: "Asset was cloned", payload: {...res, originalId: id} }});
      (window as any).dispatchEvent(evt);
    });
  }

  async mediatePresentDeleteConfirm(scriptId) {
    const options = {
      cssClass: 'delete-icon',
      header: 'Confirm!',
      message: 'Are yous sure you want to delete this script? You will not be able to add this back once removed.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Delete',
          handler: () => {
            console.log('Confirm Okay');
            this.mediateDeleteScript(scriptId);
          }
        }
      ]
    };

    this.showComplexMessage(options);
  }

  async mediatePublishUnpublish(id, status, script) {
    this.dataSource.editScript(this.dataSource.currentActiveProject.id, id, {is_published: status}).subscribe( (res) => {
      // this.viewCurrentCampaing();
      // script.is_published = !script.is_published;
    }, async (err) => {
      console.log(err);
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        // header: 'Hey There!',
        // subHeader: 'Subtitle',
        message: err.error.is_published[0],
        buttons: ['OK']
      });

      await alert.present();
    });
  }

  async mediatePresentCloneConfirm(scriptId, script?) {
    const options = {
      // cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'Are yous sure you want to clone this script?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Clone',
          handler: () => {
            console.log('Confirm Okay');
            if ( script && script.has_pack ) {
              this.mediateCloneScriptPack(scriptId);
            } else {
              this.mediateCloneScript(scriptId);
            }
          }
        }
      ]
    };

    this.showComplexMessage(options);
  }

  async mediateRenameAsset(scriptId, script?) {
    const options = {
      // cssClass: 'my-custom-class',
      header: 'Rename Asset',
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'My Awesome Asset...',
          value: script.title
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Rename',
          handler: (res) => {
            script.title = res.name1;
            this.dataSource.postAssetRename(scriptId, res.name1).subscribe( res => {

            });
          }
        }
      ]
    };

    this.showComplexMessage(options);
  }

  async mediatePresentRecreateAsset(scriptId, script?) {
    const options = {
      cssClass: 'no-icon recreate-asset-alert',
      header: 'Recreate an asset',
      message: 'Hey there! This action will delete the current asset and recreate a new asset in its place with all of the updated information from your plan.&#13;Please confirm you want to take this action!&#13;This action cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Recreate',
          handler: () => {
            console.log('Confirm Okay', script);
            let endpoint;
            if ( script.themes_list ) {
              // this.mediateCloneScriptPack(scriptId);
              // alert('themed recreate');
              console.log('script', script);
              if ( script.has_pack ) {
                endpoint = this.dataSource.getRecreateAssetThemedPack(this.dataSource.currentActiveProject.id, scriptId, script.themes_list);
              } else {
                endpoint = this.dataSource.getRecreateAssetThemed(this.dataSource.currentActiveProject.id, scriptId, script.themes_list);
              }

              // getRecreateAssetThemedPack
            } else {
              // this.mediateCloneScript(scriptId);
              endpoint = this.dataSource.getRecreateAsset(this.dataSource.currentActiveProject.id, scriptId);
            }
            endpoint.subscribe( async (res) => {
              const toast = await this.toastController.create({
                message: 'Asset was successfully re-created',
                duration: 3000,
                color: 'success'
              });
              await toast.present();
            });
          }
        }
      ]
    };

    this.showComplexMessage(options);
  }

  async mediatePresentRecreateDesignStudio(scriptId, script?) {
    console.log('script', script);
    const options = {
      cssClass: 'no-icon recreate-asset-alert',
      header: 'Replace All Pages',
      message: 'Applying a new theme will overwrite all of your current pages. &#13;No custom changes will be saved (text, icons, colors)&#13;The new theme will update with infomation from your current plan.&#13;This action cannot be undone.&#13;&#13;Confirm you would like to replace your current asset with a new theme. ',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Recreate',
          handler: () => {
            console.log('script', script);
            this.mediateSelectVariation({ themes_list: script.themes_list }, script.id, 'recreate');
          }
        }
      ]
    };

    this.showComplexMessage(options);
  }


  // Templates .
  async mediateSelectVariation(template, assetId, mode = 'create', signalId = false /*, context = false*/) {

    await this.dataSource.viewTemplateVariations(template.themes_list).subscribe( async (res) => {
      console.log('selectVariation', res);

      let runner;
      let classC = '';
      if ( template.has_steps ) {
        runner = AssetInPackCreateModalComponent;
        classC = 'fullscreen';
      } else {
        runner = DetailVariationPage;
        classC = 'select-theme-modal inner-animation';
      }

      const modal = await this.modalController.create({
        component: runner,
        cssClass: 'application-v2 modal-v2 ' + classC,
        componentProps: {
          data: res,
          assetId,
          template,
          // context: this
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();

      console.log('Wait for data:', data);

      if (data && data.selected ) {
            
        let endpoint;

        if ( mode === 'create' ) {
          if ( template.has_pack && !template.has_steps ) {
            endpoint = this.dataSource.postTemplatesCreatePdfPack(assetId, data.selected, (data.themes_list ? data.themes_list : template.themes_list) );
          } else {
            endpoint = this.dataSource.postTemplatesCreatePdf(assetId, data.selected, (data.themes_list ? data.themes_list : template.themes_list));
          }
        } else {
          // api/v2/growthworks/campaign/41/asset/2646/roadmap/change-theme/
          endpoint = this.dataSource.postAssetChangeTheme(this.dataSource.currentActiveProject.id, assetId, template.themes_list, data.selected);
        }


        const modal = await this.modalController.create({
          component: ( (template.themes_list === 'lmb_design_studio' || data.loader === 'ai') ? true : false) ? AiLoadingScreenComponent : LoadingBoxComponent,
          cssClass: 'application-v2 modal-v2 loading-box-modal',
          componentProps: {
            aiAnimation: (template.themes_list === 'lmb_design_studio' ? true : false)
          }
        });
        await modal.present();

        endpoint.subscribe( async (subRes) => {

          console.log(subRes);

          if ( signalId ) {
            modal.dismiss();

            subRes.__growthworks_signal_Id = signalId;
            (window as any).document.dispatchEvent(new CustomEvent('assetCreated', {detail: subRes}));

          } else {
            this.router.navigate(['/app/editor/' + subRes.id ], { queryParams: {settings: 'presets'} } );
          }

          
          // Hide Load
          setTimeout( async () => {
            console.log('Loading dismissed!');
          }, 2000);

        }, (err) => {

          modal.dismiss();

        });
      }

    });
  }


  // End Of - Mediate Asset Actions

  /*
  * Compose an error message to display in case of form errors
  *
  */
  composeErrorMessage(error) {
      const httpErrorCode = error.status;

      switch (httpErrorCode) {
          case 400:
              error = error.error;

              let errorMessage = '';

              Object.keys(error).forEach(key => {
                  let fieldErrors = '';

                  this.backendErrorsArray(error[key]).forEach(msg => {
                      fieldErrors += msg + ' \n';
                  });

                  errorMessage += fieldErrors;
              });

              return errorMessage;
          case 404:
              return 'Something went wrong. Please contact our support';
          default:
              return 'Something went wrong. Please try again later';
      }
  }

  /*
  * Create an array of backend errors texts (strings)
  *
  */
  backendErrorsArray(errors): Array<string> {
      let errorList: Array<string> = [];

      for (const error of Object.keys(errors)) {
          const type = typeof errors[error];
          if (type === 'object') {
              errorList = errorList.concat(this.backendErrorsArray(errors[error]));
          } else if (type === 'string') {
              errorList.push(errors[error]);
          }
      }

      return errorList;
  }

  loadAndSetDynamicFontSet(fonts) {
    console.log('loadAndSetDynamicFontSet', fonts);
    let increment = 0;
    const length = fonts.length;
    if (!length) {
      this.setPageRenderStatus(1);
    }

    const checkFontReadiness = (length, increment) => {
      if ( length === increment ) {
        const event = new Event('slides-fonts-loaded');

        setTimeout( () => { // LOad Image Set
          (window as any).document.dispatchEvent(event);
          // console.log('All Fonts Loaded', this.fontLoadedCache);

          this.setPageRenderStatus(1);

        }, 100);
      } 
    }

    fonts.forEach( (font) => {

      // alert(this.fontLoadedCache.indexOf(font));

      if ( this.fontLoadedCache.indexOf(font) !== -1 ) {

        // console.log('cache font loading initiated', length, increment);
        increment++;
        checkFontReadiness(length, increment);

      } else {

        const fontName = font; // Your font name
        // <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Trirong">
        const fontUrl = 'https://fonts.googleapis.com/css2?family=' + fontName + ':wght@300;400;500;600;700;800;900&display=swap'; // The fully qualified url of your font
  
        // https://fonts.googleapis.com/css2?family=Poppins:wght@100;700&display=swap

        const styleDom = document.createElement('style');
        styleDom.innerText = '.font-' + fontName.replace(/\s/g, '') + ' { font-family: "' + fontName + '", serif; }';
        document.head.appendChild(styleDom);
  
        this.fontLoadedCache.push( font );

        const linkDom = document.createElement('link');
        linkDom.setAttribute('rel', 'stylesheet');
        linkDom.setAttribute('crossorigin', 'anonymous');
        linkDom.setAttribute('href', fontUrl);
        linkDom.onload = (event) => {
          // console.log('font loading initiated', event, length, increment);
          increment++;
          checkFontReadiness(length, increment);
        }
        document.head.appendChild(linkDom);

      }

    });
  }

  public renderAvatar(source) {
    if ( source ) {
      return this.env.apiUrl + source;
    } else {
      return '/assets/robert.png?cache';
    }
  }

  public setPageRenderStatus(index) {
    try {
      let element = (window as any).document.getElementById('pageRenderStatus');
      if (!element) return;
      let value = element.value.split('');
      value[index] = '1';
      value = value.join('');
      element.setAttribute('value', value);

      console.log('element', element.value, value, index);

      // (window as any).document.getElementById('pageRenderStatus')

      if ( value === '1111' ) {

        setTimeout(() => {
          try {
            const elem = (window as any).document.getElementById('saveArea');
            console.log('elem', {elem});
            this.setPageRenderDimension(elem.offsetWidth * 2, elem.offsetHeight * 2);
          } catch (err) {

          }
        }, 1000);
        /*
        try {
          const elem = (window as any).document.getElementById('saveArea');
          console.log('elem', {elem});
          this.setPageRenderDimension(elem.offsetWidth * 2, elem.offsetHeight * 2);
        } catch (err) {

        }
        */
      }

    } catch (err) {
      // No Such Element
      console.log('pageRenderStatus', err);
    }
  }

  public setPageRenderDimension(w, h) {
    //alert('setPageRenderDimension');
    try {
      console.log('Resulting wxh', w, h);
      let element = (window as any).document.getElementById('pageRenderDimension');
      if (!element) return;
      element.setAttribute('value', w + 'x' + h);
      // (window as any).document.getElementById('pageRenderStatus')
    } catch (err) {
      // No Such Element
      console.log('pageRenderDimension', err);
    }
  }

  public async promptAppUpdate() {

    if ( !this.updateAlert ) {

      const options = {
        header: 'Hey There!!! Great News!!',
        message: `Version update released for performance, features and back end processes.

Dismiss to save your work and then update or update now if ready.

Refresh your browser after update.`,
        backdropDismiss: false,
        cssClass: 'app-update-alert',
        buttons: [
          {
            text: 'Dismiss for a minute',
            role: 'cancel',
            handler: () => {
              setTimeout( () => {
                this.promptAppUpdate();
              }, 60 * 1000);
            }
          }, {
            text: 'Get New Version',
            // cssClass: 'danger',
            handler: async (res) => {
              const loading = await this.loadingController.create({
                // cssClass: 'my-custom-class',
                message: 'Activating New Update...',
                // duration: 2000,
              });
              await loading.present();
              this.serviceWorkerUpdate.activateUpdate().then(() => {
                (window as any).location.reload();
              });
            }
          }
        ]
      };

      this.updateAlert = await this.alertController.create({
        ...options,
        cssClass: 'application-v2 alert-v2 ' + (options.cssClass ? options.cssClass : ''),
      });
      await this.updateAlert.present();

      await this.updateAlert.onDidDismiss();
      this.updateAlert = null;

    }
  }

  async copyToClipboard(text) {
    function decodeEntities(s){
      var str, temp= document.createElement('p');
      temp.innerHTML= s;
      str= temp.textContent || temp.innerText;
      temp=null;
      return str;
    }
    console.log('copyContent');
    const toast = await this.toastController.create({
      message: 'Copied to clipboard: ' + text,
      duration: 2000
    });
    toast.present();

    const write = decodeEntities(text);
    console.log(write);

    (window as any).navigator.clipboard.writeText(write).then().catch(e => console.error(e));
  }

  // Share Link Popup
  async openShareLinkPopup(url, title, subtitle) { // CopyPaste
    const modal = await this.modalController.create({
      component: PopupShareLinkComponent,
      cssClass: 'application-v2 modal-v2 share-link-modal',
      componentProps: {
        link: url,
        title,
        subtitle,
      }
    });
    await modal.present();
    /*
    const { data } = await modal.onWillDismiss();
    // console.log('Wait for data:', data);
    if (data && data.forward ) {
      console.log('Forward to', data.forward);
      this.router.navigate([data.forward]);
    }
    */
  }

  // Agency Branding Resources
  protected universalPlatformEnvKey = 'agencyBrandingRendering';

  public isPlatformUniversal() {
    if ( this.enviroment('agencyBrandingRendering') ) {
      return true;
    } else {
      return false;
    }
  }

  public getPlatformLogotype() {
    if ( this.enviroment(this.universalPlatformEnvKey) ) {
      // domain wildcarded path
      const subdomain = window.location.hostname.split('.').slice(0, -2).join('.') || 'localhost';
      return this.env.apiUrl + '/media/agencies/' + subdomain + '/logo.png?universalplatform=' + this.universalPlatformLogoImageCacheTimestamp;
    } else {
      return this.env.appUrl + '/assets/original-branding/logotype.svg';
    }
  }

  public getPlatformLogo() {
    if ( this.enviroment(this.universalPlatformEnvKey) ) {
      // domain wildcarded path
      const subdomain = window.location.hostname.split('.').slice(0, -2).join('.') || 'localhost';
      return this.env.apiUrl + '/media/agencies/' + subdomain + '/logotype.png?universalplatform=' + this.universalPlatformLogoImageCacheTimestamp;
    } else {
      return this.env.appUrl + '/assets/original-branding/logo.png';
    }
  }

  public getPlatformName() {
    if ( this.enviroment('agencyBrandingRendering') ) {
      // feature, now changing to domain name
      // https://iteksasnew.growbotic.io/
      let hostname = window.location.hostname.replace('www.', '');
      //split if can and take first part
      hostname = hostname.split('.')[0];
      
      if ( hostname === 'localhost' ) {
        return 'Running Locally';
      }

      return hostname;
    } else {
      return 'GrowthWorks';
    }
  }

  public appFeature(flag) {
    if ( this.env['release' + flag] === true ) {
      return true;
    } else {
      return false;
    }
  }

  // Upgrade Profile Modal / Upgrade Subscription (Shared)
  public upgradeProfileModal(mode, link = null) {
    this.modalController.create({
      component: ProfileUpgradeModalComponent,
      cssClass: 'application-v2 modal-v2 profile-upgrade-modal',
      componentProps: {
        link,
        mode
      }
    }).then( (modal) => {
      modal.present();
    });
  }

  public presentWebisteSettingsUpdateToster() {
    // Changes made to your domain or page settings can take up to 2 minutes to go live on the interwebs.. please be patient!
    this.toastController.create({
      message:  'Changes made to your domain or page settings can take up to 2 minutes to go live on the interwebs.. please be patient!',
      duration: 0,
      position: 'bottom',
      color: 'warning',
      cssClass: 'application-v2 toast-v2 websites-updated-toast',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    }).then(toast => toast.present());
  }

  // public presentCampaignDuplicateChangesToster() {
  //   // Changes made to your domain or page settings can take up to 2 minutes to go live on the interwebs.. please be patient!
  //   this.toastController.create({
  //     message:  'Changes will be available in a few minutes.. please be patient!',
  //     duration: 0,
  //     position: 'bottom',
  //     color: 'warning',
  //     cssClass: 'application-v2 toast-v2 websites-updated-toast',
  //     buttons: [
  //       {
  //         text: 'OK',
  //         role: 'cancel',
  //         handler: () => {
  //           console.log('Cancel clicked');
  //         }
  //       }
  //     ],
  //   }).then(toast => toast.present());
  // }

  // public async presentCampaignDuplicateChangesToster(cloningToaster) {
  //   const toast = await this.toastController.create({
  //     message: 'Changes will be available in a few minutes.. please be patient!',
  //     duration: 0,
  //     position: 'bottom',
  //     color: 'warning',
  //     cssClass: 'application-v2 toast-v2 websites-updated-toast',
  //     buttons: [
  //       {
  //         text: 'OK',
  //         role: 'cancel',
  //         handler: () => {
  //           console.log('Cancel clicked');
  //         }
  //       }
  //     ],
  //   });
  
  //   await toast.present();
  
  //   const { role } = await toast.onDidDismiss();
    
  //   if (role === 'cancel') {
  //     cloningToaster = null;
  //   }
  // }

  public async presentCampaignDuplicateChangesToster() {

    const toast = await this.toastController.create({
      message: 'This campaign is currently being cloned. Data is still being copied, so please avoid editing to ensure accurate results.',
      duration: 0,
      position: 'bottom',
      color: 'warning',
      cssClass: 'application-v2 toast-v2 websites-updated-toast',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
    });

    this.campaignCloningToaster = toast;
  
    await toast.present();

    toast.onDidDismiss().then(() => {
      this.campaignCloningToaster = null;
      console.log('Toaster dismissed');
    });
  }

  // async editPageNamePopover(item, ev?) {
  //   const popover = await this.popoverController.create({
  //     component: RenameCustomPagePopoverComponent,
  //     cssClass: 'application-v2 popover-v2 save-custom-page-popover',
  //     event: ev,
  //     translucent: true,
  //     componentProps: { item }
  //   });
    
  //   await popover.present();

  //   // const { data } = await popover.onDidDismiss();
  //   // if (data) {
  //   //   this.updateItemData(id, data.name, data.group);
  //   // }

  //   const { data } = await popover.onDidDismiss();
  //   if (data?.reload) {
  //     this.ngOnInit();
  //   }
  // }
  
  

  public presentFunnelsCreateModal() {
    this.modalController.create({
      component: FunnelsCreateModalComponent,
      cssClass: 'application-v2 modal-v2 funnels-create-modal-wizard',
      componentProps: {
        link: '',
      }
    }).then( (modal) => {
      modal.present();
    });
  }

  public callSupport() {
    //
    try{
      console.log( (window as any).Intercom('show') );
    } catch (err) {
      console.log('callSupport - Intercom not loaded');
    }
  }

  public async presentAccountUpgradeModal(reason?) {
    const modal = await this.modalController.create({
      component: UniversalAccountUpgradeModalComponent,
      cssClass: 'application-v2 modal-v2 universal-account-upgrade-modal',
      componentProps: {
        reason
      }
    });
    await modal.present();
  }

}
