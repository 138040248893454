<div class="wrapper">

  <div class="btn-wrap">
    <ion-buttons>
      <ion-button (click)="dismiss()" size="small">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>

  <img src="/assets/v2/icons/from-saved.png" width="48">
  <div class="box">
    <h5>You can’t edit in mobile view.</h5>
    <p>To begin editing your campaign data, you need to open our app on a desktop.</p>
  </div>

</div>