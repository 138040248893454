<!-- Injecting Branding Hacked (Agency Wildcard Logotype / Universal Platform Logotype) Links-->
<ion-app [ngClass]="{ 'is-mobile-view': formProcessor.isMobileView }">

  <ion-router-outlet class="application-v2" [ngClass]="{ 'admin-outline' : auth.hasMainToken || dataSource.isCoaching }" id="main-content"></ion-router-outlet>

  <!--
  <ion-fab vertical="bottom" horizontal="start" slot="fixed" *ngIf="auth.hasMainToken" (click)="recoverMainToken()">
    <ion-fab-button color="dark">
      <ion-icon name="return-up-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-fab vertical="bottom" horizontal="start" slot="fixed" *ngIf="dataSource.isCoaching" (click)="recoverCoaching()">
    <ion-fab-button color="dark">
      <ion-icon name="return-up-back-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  -->

  <!--
  <div class="back-bar" *ngIf="auth.hasMainToken">
    <ion-row>
      <ion-col (click)="recoverMainToken()" size="1" class="ion-text-right active"><ion-icon name="arrow-back-outline"></ion-icon></ion-col>
      <ion-col (click)="recoverMainToken()" class="ion-text-left active">Back to my coach center</ion-col>
      <ion-col class="ion-text-center">As User: {{ auth.data.first_name + ' ' + auth.data.last_name }}</ion-col>
      <ion-col></ion-col>
      <ion-col size="1"></ion-col>
    </ion-row>
  </div>

  <div class="back-bar" *ngIf="dataSource.isCoaching">
    <ion-row>
      <ion-col (click)="recoverCoaching()" size="1" class="ion-text-right active"><ion-icon name="arrow-back-outline"></ion-icon></ion-col>
      <ion-col (click)="recoverCoaching()" class="ion-text-left active">Back to my coach center</ion-col>
      <ion-col class="ion-text-center">Coaching: {{ dataSource.coachingData.coachingName }}</ion-col>
      <ion-col></ion-col>
      <ion-col size="1"></ion-col>
    </ion-row>
  </div>
  -->

  <!--
  <ion-fab class="back-fab" vertical="top" horizontal="end" slot="fixed" *ngIf="auth.hasMainToken || dataSource.isCoaching">
    <ion-badge color="medium" *ngIf="auth.hasMainToken">As User: {{ auth.data.first_name + ' ' + auth.data.last_name }}</ion-badge>
    <ion-badge color="medium" *ngIf="dataSource.isCoaching">Coaching: {{ dataSource.coachingData.coachingName }}</ion-badge>
    <ion-fab-button>
      <ion-icon name="key-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="bottom">
      <ion-fab-button (click)="auth.hasMainToken ? recoverMainToken() : recoverCoaching()" color="danger"><ion-icon name="log-out-outline"></ion-icon></ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  -->

  <div class="admin-overlay" *ngIf="auth.hasMainToken || dataSource.isCoaching">

    <ion-button class="hover" color="primary" size="small" fill="solid" (click)="auth.hasMainToken ? recoverMainToken() : recoverCoaching()">
      <ion-icon slot="start" name="log-out-outline"></ion-icon> Back to Agency
    </ion-button>

    <div class="active">
      <span *ngIf="auth.hasMainToken">As User: {{ auth.data.first_name + ' ' + auth.data.last_name }}</span>
      <span *ngIf="dataSource.isCoaching">Coaching: {{ dataSource.coachingData.coachingName }}</span>
    </div>

  </div>

</ion-app>
