import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { FormProcessorService } from '@services/form-processor.service';
import { DataSourceService } from '@services/data-source.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choose-campaign-popover',
  templateUrl: './choose-campaign-popover.component.html',
  styleUrls: ['./choose-campaign-popover.component.scss'],
})
export class ChooseCampaignPopoverComponent implements OnInit {
  @Input() campaigns: any[] = [];

  constructor(
    public popoverController: PopoverController,
    public formProcessor: FormProcessorService,
    public dataSource: DataSourceService,
    public router: Router,
  ) { }

  ngOnInit() {
  }


  dismiss() {
    this.popoverController.dismiss();
  }

  openCampaing(campaign) {
    setTimeout(() => {
      // Update current active project
      this.dataSource.setCurrentActiveProject(campaign, true);

      this.dismiss();

      // Redirect to selected campaign
      if (campaign.id) {
        this.router.navigate(['/app/dashboard', campaign.id], { 
          replaceUrl: false,
          queryParams: { refresh: true } 
        });
      }
    }, 50);
  }

}
