import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-info-mobile-popover',
  templateUrl: './info-mobile-popover.component.html',
  styleUrls: ['./info-mobile-popover.component.scss'],
})
export class InfoMobilePopoverComponent implements OnInit {

  constructor(
    public popoverController: PopoverController,
  ) { }

  ngOnInit() {}

  dismiss() {
    this.popoverController.dismiss();
  }

}
